.wrapper {
    margin-top: 5vh;
  }
  
  .dataTables_filter {
    float: right;
  }
  
  .table-hover > tbody > tr:hover {
    background-color: #ccffff;
  }
  
  @media only screen and (min-width: 768px) {
    .table {
      table-layout: fixed;
      max-width: 100% !important;
    }
  }
  
  thead {
    background: #ddd;
  }
  
  .table td:nth-child(2) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .highlight {
    background: #ffff99;
  }
  
  @media only screen and (max-width: 767px) {
    /* Force table to not be like tables anymore */
    table,
  thead,
  tbody,
  th,
  td,
  tr {
      display: block;
    }
  
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr,
  tfoot tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  
    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 50% !important;
    }
  
    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }
  
    .table td:nth-child(1) {
      background: #ccc;
      height: 100%;
      top: 0;
      left: 0;
      font-weight: bold;
    }
  
    /*
    Label the data
    */
    td:nth-of-type(1):before {
      content: "First Name";
    }
  
    td:nth-of-type(2):before {
      content: "Last Name";
    }
  
    td:nth-of-type(3):before {
      content: "Email";
    }
  
    td:nth-of-type(4):before {
      content: "Phone Number";
    }
  
    td:nth-of-type(5):before {
      content: "Role";
    }
  
    td:nth-of-type(6):before {
      content: "Action";
    }
  
    .dataTables_length {
      display: none;
    }
  }