:root {
  scroll-behavior: smooth;
}

a {
  color: #010a14 !important;
  text-decoration: none !important;
  white-space: nowrap;
}

a:hover {
  color: #010a14 !important;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #474d58;
  font-weight: 100;
  line-height: 1.2;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 26px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.8;
}

li {
  list-style: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
  color: #010a14 !important;
  font-weight: 300;
}

#header {
  background: #fff;
  z-index: 997;
  transition: all 0.5s ease;
  /* padding: 20px 0; */
}

#header #logo h1 {
  color: #505050;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0
}

#header #logo h3 {
  color: #010a14 !important;
  display: inline-block;
  font-weight: 500;
  text-decoration: none;
}

#header #logo h3 a span {
  color: #010a14 !important;
}

#header #logo img {
  padding: 0;
  margin: 0;
}

#header.header-scrolled {
  background: #fff;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.1);
  padding: 12px 0;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  /* display: flex; */
  /* align-items: center;
  justify-content: space-between; */
  /* padding: 10px 0 10px 30px; */
  font-size: 14px;
  color: #010a14 !important;
  white-space: nowrap;
  transition: 0.3s;
  letter-spacing: 0;
  line-height: 2;
  font-weight:bold;
  font-family: "Nunito", sans-serif !important;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover>a {
  color: #010a14 !important;
}

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  text-transform: none;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover>a {
  color: #010a14 !important;
}

.navbar .dropdown:hover>ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover>ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover>ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #010a14 !important;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
  /* padding:  10px 10px ; */
  line-height: 2;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .navbar{
    padding: 0px !important;
  }
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
 
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(34, 34, 34, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 6px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #010a14 !important;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover>a {
  color: #010a14 !important;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover>a {
  color: #010a14 !important;
}

.navbar-mobile .dropdown>.dropdown-active {
  display: block;
}
.dropbtn {
  padding-left: 30px;
  font-size: 16px;
  border: none;
  background-color: transparent;
  color: #1D293E;
  font-weight: bolder;

}

/* .messageCont{
  align-items: center;
  display: block;
  justify-content: center;
} */

.messaging{
  left:-10;
}

@media (max-width: 991px){
  .messageCont h5{
    display: none;
  }
}
.messaging{
  display: none;
}
.messageIcon:hover + .messaging{
  width: 100%;
  display: block;
  z-index: 1000;
  /* margin-top: 100px; */
}
/* .messageIcon:hover{
  display: none;
} */

.active-link {
  background-color: lightgray;
  border-radius: 12px;
}

@media (max-width: 1024px) {
  /* #header #logo h1 {
    font-size: 24px;
  } */
  .navbar a {
    font-size: 15px;
  }
}
@media  (max-width : 1280px){
  /* #header #logo h1 {
    font-size: 24px;
  } */
  .navbar a {
    padding: 5px 10px; 
    font-size: 15px;
   }
}