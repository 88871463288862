.home {
    /* background-color: #a7bcff; */
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 120px;
}

.container-chat {
    border: 1px solid white;
    border-radius: 10px;
    width: 65%;
    height: 80%;
    display: flex;
    overflow: hidden;
}
.chat-scroll ::-webkit-scrollbar {
    width: 15px;
  }
  
  .chat-scroll ::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  }
  
  .chat-scroll ::-webkit-scrollbar-track {
    background-color: #f2f2f2;
    border-radius: 10px;
  }
.sidebar {
    flex: 1;
    background-color: #071c36;
    position: relative;
}

.chat {
    flex: 2
}

.chats {
    height: 100%;
    overflow: scroll;
}

.userChat {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: white;
    cursor: pointer;
}

.avatar {
    display: inline-flex; 
    width: 50px;
    height: 50px;
    border-radius: 50%; 
    border: 2px solid aquamarine;
    box-sizing: border-box; 
}

.avatar-text{
    font-size: 18px;
    font-weight: bold;
}
.imgChat {
    width: 50px;
    height: 50px;
    border-radius: 1;
    border-radius: 50%;
    object-fit: cover;
    border-color: white;
}

.search {
    border-bottom: 1px solid gray;
}

.searchForm {
    padding: 10px;
}

input {
    /* background-color: transparent; */
    border: none;
    color: white;
    outline: none;
    padding: 2px;
    border-radius: 10px;
    font-size: 12px;
}

span {
    font-size: 18px;
    font-weight: 500;
}

p {
    font-size: 14px;
    color: lightgray;
}

.chatInfo {
    height: 50px;
    background-color: #071c36;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: rgb(252, 147, 147);
}

.chatIcons {
    display: flex;
    gap: 20px;

}

/* img {
    height: 24px;
    cursor: pointer;
} */

.messages {
    background-color: #ddddf7;
    padding: 10px;
    height: calc(100% - 100px);
    overflow: scroll;
}

.input {
    height: 50px;
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

input {
    width: 100%;
    border: none;
    outline: none;
    color: #2f2d52;
    font-size: 18px;
}

.send {
    display: flex;
    align-items: center;
    gap: 8px;
}

.icon {
    display: flex;
    justify-self: center;
    height: 24px;
    cursor: pointer;
    margin-top: 10px;
}

button {
    border: none;
    padding: 10px 15px;
    color: white;
    background-color: #8da4f1;
    cursor: pointer;
}

.messageSend {
    display: block;
    gap: 20px;
    /* margin-bottom: 20px; */
    /* justify-content: space-between; */
}

.messageInfo {
    display: flex;
    flex-direction: column;
    color: gray;
    font-weight: 300;
    gap: 10px;
}

.imgInfo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.messageContent {
    max-width: 100%;
    display: flex;
    flex-direction: column;
}

p {
    padding: 10px 20px;
    border-radius: 0px 10px 10px 10px;
    max-width: max-content;
}


.para {
    border-radius: 10px 0px 10px 10px;
}

.message-time{
    font-size: 10px;
    align-self: flex-end;
    margin-left: 12px;
}