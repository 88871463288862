 .navbar1 {
    padding: 0;
  }
  
  .navbar1 ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  
  .navbar1 li {
    position: relative;
  }
  
  .navbar1 a,
  .navbar1 a:focus {
    /* display: flex; */
    align-items: center;
    justify-content: space-between;
    /* padding: 10px 0 10px 30px; */
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #010a14 !important;
    white-space: nowrap;
    transition: 0.3s;
    letter-spacing: 0;
    line-height: 2;
    font-weight:bold;
  }
  
  .navbar1 a i,
  .navbar1 a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  
  .navbar1 a:hover,
  .navbar1 .active,
  .navbar1 .active:focus,
  .navbar1 li:hover>a {
    color: #010a14 !important;
  }
  
  .navbar1 .dropdown1 ul {
    display: block;
    position: absolute;
    left: 1px;
    transform: translateY(-10%);
    top: calc(100% + 15px); 
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    border-radius: 4px;
  }
  
  .navbar1 .dropdown1 ul li {
    min-width: 200px;
  }
  
  .navbar1 .dropdown1 ul a {
    padding: 10px 20px;
    text-transform: none;
  }
  
  .navbar1 .dropdown1 ul a i {
    font-size: 12px;
  }
  
  .navbar1 .dropdown1 ul a:hover,
  .navbar1 .dropdown1 ul .active:hover,
  .navbar1 .dropdown1 ul li:hover>a {
    color: #010a14 !important;
  }
  
  .navbar1 .dropdown1:hover>ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  
  .navbar1 .dropdown1 .dropdown1 ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  
  .navbar1 .dropdown1 .dropdown1:hover>ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  
  @media (max-width: 1366px) {
    .navbar1 .dropdown1 .dropdown1 ul {
      left: -90%;
    }
  
    .navbar1 .dropdown1 .dropdown1:hover>ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #010a14 !important;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
    /* padding:  10px 10px ; */
    line-height: 2;
  }
  
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .navbar1{
      padding: 0px !important;
    }
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar1 ul {
      display: none;
    }
   
  }
  
  .navbar1-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(34, 34, 34, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  
  .navbar1-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  
  .navbar1-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 6px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  
  .navbar1-mobile a,
  .navbar1-mobile a:focus {
    padding: 10px 20px;
    font-size: 15px;
    color: #010a14 !important;
  }
  
  .navbar1-mobile a:hover,
  .navbar1-mobile .active,
  .navbar1-mobile li:hover>a {
    color: #010a14 !important;
  }
  
  .navbar1-mobile .getstarted,
  .navbar1-mobile .getstarted:focus {
    margin: 15px;
  }
  
  .navbar1-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  
  .navbar1-mobile .dropdown ul li {
    min-width: 200px;
  }
  
  .navbar1-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  
  .navbar1-mobile .dropdown ul a i {
    font-size: 12px;
  }

@media (max-width: 1024px) {
  .navbar1 ul{
    display: none;
  }
}

.top-ussd{
  margin-top: 60px;
  padding-top: 5px;
}

.sms-style .sms-text{
  font-size: 12px
}

.sms-style .sms-title{
  font-size: 12px;
  font-weight: bold;
  /* color:#0E7BE3 !important; */
}
